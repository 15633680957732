// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mdx-fields-slug-jsx": () => import("./../../../src/pages/{Mdx.fields__slug}.jsx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-jsx" */),
  "component---src-pages-theming-jsx": () => import("./../../../src/pages/theming.jsx" /* webpackChunkName: "component---src-pages-theming-jsx" */)
}

